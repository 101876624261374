/** WARNING: Do NOT import this file in any component style */
@import 'variables';

/* stylelint-disable selector-max-type */
html,
body {
  height: 100%;
}

body {
  line-height: 1;
  background-color: #fafafa;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

input,
button {
  border: none;
  background: none;
}

/* stylelint-enable selector-max-type */

.mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
}

.dp-picker-input {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.12;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Roboto', 'Helvetica Neue', sans-serif !important;
}

.met-fake-mat-form-field-wrapper {
  margin-bottom: $spacing-xl;
}

.met-fake-mat-form-field {
  border-radius: 5px;
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid $color-grey;
  padding: 10px;
  position: relative;
}

.met-invalid-fake-mat-form-field {
  border: 2px solid $color-warn !important;

  .met-fake-mat-label {
    position: absolute;
    top: -9px;
    left: 6px;
    background-color: $color-white;
    font-size: 12px;
    padding: 5px;
    color: $color-warn;
  }
}

.met-fake-mat-label {
  position: absolute;
  top: -9px;
  left: 6px;
  background-color: $color-white;
  font-size: 12px;
  padding: 5px;
  color: rgba(0, 0, 0, 0.6);
}

.met-fake-mat-error {
  font-size: 75%;
  padding: 0 1em;
  margin-top: $spacing-s;
}

.met-nav-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-items: center;
  height: 100%;
  color: $color-white;
  padding: 0 12px;
}

.met-nav-item-active {
  background-color: #1e3c72 !important;
}

.met-modal-buttons {
  display: flex;
  justify-content: center;
}

.met-border {
  border: 1px solid $color-grey;
  border-radius: 5px;
}

.template-inputs {
  padding-bottom: 1rem;
  .mat-form-field-wrapper {
    padding: 0;
  }

  .remove-value {
    &.mat-mini-fab .mat-button-wrapper {
      padding: 0;
      display: block;
      line-height: 15px;
    }
  }
}
