/* ----- SPACINGS ----- */
$spacing-s: 8px;
$spacing-m: 12px;
$spacing-l: 16px;
$spacing-xl: 24px;
$warning-detail-spacing: 5vw;

/* ----- SIZES ----- */
$min-modal-width: 350px;
$table-no-results-height: 250px;
$min-statistic-table-column-width: 200px;
$warning-detail-line-height: 25px;

/* ----- COLORS ----- */
$color-grey: #e3e3e3;
$color-light-grey: rgb(243, 243, 243);
$color-white: #ffffff;
$color-warn: #f44636;
